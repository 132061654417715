<template>
  <div class="home">
    <div class="hero">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8">
            <img src="@/assets/allsorts.png" alt="All Sorts" class="logo" />
            <div class="row">
              <div class="col-lg-10">
                <p>A curated collection of quality products you'll love from across the internet</p>
                <div class="buttons">
                  <a href="/catalog" class="btn btn-primary" target="_blank" rel="noopener noreferrer">View catalog</a>
                  <router-link to="/partner" class="btn btn-outline-primary">Partner with us</router-link>
                </div>
                <div class="arrow-container">
                  <button type="button" class="btn btn-link arrow-btn" v-on:click="goToBrands">
                    <img src="@/assets/down-arrow-new.svg" class="arrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="main" id="section2">
      <div ref="brands" id="brands-anchor"></div>
      <div class="container">
        <div class="mb-3 brand-filter row align-items-center">
          <div class="col-12 title">
            <h2>Available Brands</h2>
          </div>
          <div class="col-md-6 info">
            Displaying {{filteredBrands.length}} brands
          </div>
          <div class="col-md-6">
            <div class="brand-types d-flex align-items-center">
              <span>Show: 
                <b class="dropdown">{{selectedCategory}} 
                  <div class="dropdown-content">
                    <div class="dropdown-content-item" 
                      v-for='category in categories'
                      :key='category'
                      :style="{
                        'font-weight': selectedCategory==category ? 'bold' : 'normal'
                      }"
                      v-on:click="onCategoryClick($event, category)">
                        {{category}}
                      </div>
                  </div>
                  <img src="../assets/dropdown-triangle.svg" />
                </b>
              </span>
              <div class="search-box d-flex align-items-center">
                <img src="../assets/ic_search.svg" />
                <input v-model="filter" placeholder="Search by keyword">
              </div>
            </div>
          </div>
        </div>
        <div class="brands row">
          <a class="brand-item" v-for='brand in filteredBrands'
              :key='brand.name'
              :href='brand.link'
              target="_blank" rel="noopener noreferrer">
                <brand-component 
                  :background='brand.background'
                  :backgroundHover='brand.backgroundHover'/>
            </a>
        </div>
      </div>
    </div>
    <div class="survey">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div class="magazines">
              <img src="@/assets/mags.png" alt="All Sorts Magazines" />
            </div>
          </div>
          <div class="col-lg-7 col-md-8 right">
            <h3>Did you receive our latest catalog?</h3>
            <p>If you did, and want to make sure that you receive the next one, please complete this short survey.</p>
            <Survey />
          </div>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import axios from "axios"
// import Hero from '../components/Hero.vue';
import Footer from '../components/Footer.vue';
import Brands from '../brands';
import Brand from '../components/Brand.vue';
import Survey from '../components/Survey.vue';
export default {
  name: 'Home',
  data: () => {
    return {
      name: "",
      brandName: "",
      brandURL: "",
      message: "",
      email: "",
      phoneNumber: "",
      errors: [],
      isLoading: false,
      isSent: false,
      formError: "",
      brands: Brands,
      filter:"",
      categories:["All brand types", "Clothing & Accessories", "Nutrition & Wellness", "Home & Kitchen", "Travel & Leisure", "Family & Pets"],
      selectedCategory:"All brand types",
      fontsReady: false,
    }
  },
  computed: {
    filteredBrands: function () {
      return this.brands.filter((brand)=>{
        if(this.filter){
          return brand.keywords.find((word)=> word.includes(this.filter));
        }else{
          return true;
        }
      }).filter((brand)=>brand.categories.includes(this.selectedCategory)||this.selectedCategory==="All brand types");
    }
  },
  mounted: function() {
    document.fonts.ready.then(() => {
      this.fontsReady = true;
    });
  },
  methods: {
    onCategoryClick(e, category){
      this.selectedCategory = category;
    },
    /**
     * Encode form data for xhr request
     */
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    /**
     * Handle submitting the form
     */
    submit: function() {
      // reset validation
      this.errors = []
      this.isLoading = false
      this.isSent = false
      this.formError = ''

      // validate
      const fields = [
        'name',
        'email',
        'message'
      ]

      // ensure each field is present
      fields.forEach((field) => {
        if (this[field].trim() == '') {
          this.errors.push(field)
        }
      })

      // validate email
      if (!/@/.test(this.email)) {
        this.errors.push('email')
      }

      if (this.errors.length == 0) {
        // no errors, set loading state
        this.isLoading = true

        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };

        // send request
        axios.post(
            "/",
            this.encode({
              "form-name": "contact",
              "name": this.name,
              "email": this.email,
              "phone": this.phoneNumber,
              "brand": this.brandName,
              "brandUrl": this.brandURL,
              "message": this.message,
            }),
            axiosConfig
          )
          .then(() => {
            // success, reset
            this.name = ''
            this.email = ''
            this.phoneNumber = ''
            this.brandName = ''
            this.brandURL = ''
            this.message = ''
            this.isLoading = false

            // show success state
            this.isSent = true
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err)

            // show error
            this.isLoading = false
            this.formError = 'Sorry, something went wrong. Please try again later.'
          })
      }
    },
    goToBrands: function() {
      this.$refs.brands.scrollIntoView({ 
        behavior: 'smooth' 
      });
    },
  },
  components: {
    // Hero,
    'footer-component':Footer,
    'brand-component':Brand,
    Survey,
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";

.hero {
  height: 600px;
  max-height: 80vh;
  display: flex;

  @media all and (max-width: $smallMax) {
    display: block;
    padding: 6rem 0 0;
  }

  > .container {
    background-image: url('../assets/topillus.png');
    background-size: auto 100%;
    background-position: right top -20px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    @media all and (max-width: 1199px) {
      background-size: 40% auto;
    }

    @media all and (max-width: $mediumMax) {
      background-size: 30% auto;
    }

    @media all and (max-width: $smallMax) {
      background-image: none;
    }

    > .row {
      width: calc(100% + 30px);
    }
  }

  .logo {
    width: 100%;
    height: auto;
    margin: 2rem 0 0;
    transform: translateX(-50px);

    @media all and (max-width: 1099px) {
      transform: none;
    }
  }
  
  p {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    margin: 2.5rem 0;

    @media all and (max-width: $smallMax) {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media all and (max-width: $smallMax) {
      flex-direction: column;
    }

    a {
      margin: 0 2rem 0 0;
      font-size: 1rem;
      min-width: auto;

      @media all and (max-width: $smallMax) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .arrow-container {
    margin: 2rem 0 0;

    @media all and (max-width: $smallMax) {
      text-align: center;
    }
  }

  .arrow-btn {
    padding: 0;
  }

  .arrow {
    width: 24px;
    height: auto;
  }
}

#brands-anchor {
  transform: translateY(-100px);
}

.brand-filter {

  .title {
    border-top: 1px solid #E9E9E9;
    padding-top: 1rem;

    h2 {
      font-family: $mainFont;
      color: black;
    }
  }
  .info{
    font-family: "Ubuntu";
    font-size: 16px;
    color: #5CA79E;
    line-height: 26px;
    font-weight: bold;
  }
  .brand-types{
    justify-content: flex-end;
    color:black;
    @include md {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .dropdown {
      position: relative;
      display: inline-block;
      margin: 0 2rem 0 0.75rem;
      & :hover{
        cursor: pointer;
      }
      @include md {
        margin-top: 10px;
      }
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 205px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      .dropdown-content-item{
        padding: 6px 16px;
        font-weight: normal;
      }
      & :hover{
        cursor: pointer;
        background-color: #b8b8b8;
      }
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }
  .search-box{
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 3px;
    & img {
      padding:10px;
    }
    & input{
      border:none;
      font-family: "Ubuntu"!important;
      &:focus{
        outline: none;
      }
    }
    @include md {
      margin-top: 10px;
      width: 100%;
    }
  }
}
.brands{
  min-height: 280px;
  .brand-item{
    width: 25%;
    height: 280px;
    padding: 15px;
    @include sm {
      width: 100%;
      height: 350px;
    }
  }
}

div.main {
  margin-top: 40px;

  div.row {
    &.reverse-mobile {
      @include sm {
        flex-direction: column-reverse;
      }
    }
    &.title-row {
      border-bottom: 1px solid rgba(black, 0.09);
      margin-bottom: 2.5rem;
    }
  }

  div.block {
    padding-top: 7.5rem;

    @include sm {
      padding-top: 5rem;
    }

    &.center {
      text-align: center;

      h2 {
        margin-bottom: 5.125rem;
      }
    }

    &.middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.survey {
  margin: 12rem 0 0;

  .magazines {
    background-image: url('../assets/magbacks.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 250px;
      max-width: 100%;
      height: auto;
      margin: 0 1.5rem 0 0;

      @media all and (max-width: $smallMax) {
        margin-bottom: 2rem;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  h3 {
    margin: 0 0 1.4375rem;
    font-size: 2.1875rem;
    font-weight: 700;
    font-family: $headingFont;
    line-height: calc(45/35);
    color: black;
  }

  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    line-height: calc(26/16);
    margin: 0 0 2rem;
    max-width: 500px;
  }
}
</style>
