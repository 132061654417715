<template>
  <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="social">
              <li>
                <a href="https://www.instagram.com/loveallsortsthings" target="_blank" rel="noopener noreferrer" title="Instagram">
                  <img src="../assets/instagram-new.svg" alt="Instagram" />                
                </a>
              </li>
              <li>
                <a href="https://twitter.com/loveallsorts" target="_blank" rel="noopener noreferrer" title="Twitter">
                  <img src="../assets/twitter-new.svg" alt="Twitter" />                
                </a>
              </li>
              <li>
                <a href="mailto:hello@loveallsorts.com" title="Email">
                  <img src="../assets/email.svg" alt="Email" />
                  <span>hello@loveallsorts.com</span>
                </a>
              </li>
            </ul>
            <p class="copy">&copy; {{ year }} All Sorts. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data: () => {
      let now = new Date()
      return {
        year: now.getFullYear(),
      }
    },
    props: {
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";
div.footer {
  margin-top: 6rem;
  background-color: white;
  background-image: url('../assets/footer-bg.png');
  background-size: 100% 100%;
  background-position: center top;
  text-align: center;
  color: #AFAFAF;
  padding: 4rem 0;

  .email {
    font-weight: 700;
    color: $green;
    text-decoration: underline;
    display: inline-block;
    margin: 0 0 2rem;
  }

  ul.social {
    list-style: none;
    margin: 0 0 1.4375rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 0.5rem;

      a {
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        font-weight: 600;
      }

      img {
        width: 28px;
        height: auto;
      }

      span {
        padding: 0 0 0 1rem;
        display: inline-block;
      }
    }
  }

  .copy {
    text-align: center;
    color: black;
    font-weight: 600;
    font-size: 0.875rem;
    margin: 0;
  }
}
</style>