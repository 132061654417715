<template>
  <div id="app">
    <Header />
    <transition name="pageTransition" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: 'App',
  components: {
    Header,
  },
}
</script>

<style lang="scss">
// page transition, fade out and slide down
.pageTransition-enter-active, .pageTransition-leave-active {
  transition: opacity 500ms, transform 500ms;
}
.pageTransition-enter, .pageTransition-leave-to {
  opacity: 0;
  transform: translateY(100px) !important;
}
</style>
