export default [
  {
    background: "/brands/branch.png",
    backgroundHover: "/brands/hover-branch.png",
    keywords: ["branch"],
    categories: ["Home & Kitchen"], 
    link: "https://www.branchfurniture.com/collections/work-from-home?utm_source=DM&utm_medium=AllSorts&utm_campaign=Pilot_Mail"
  },
  // {
  //   background: "/brands/brooklinen.png",
  //   backgroundHover: "/brands/hover-brooklinen.png",
  //   keywords: ["brooklinen"],
  //   categories: ["Home & Kitchen"], 
  //   link: "http://brooklinen.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  {
    background: "/brands/caramba.png",
    backgroundHover: "/brands/hover-caramba.png",
    keywords: ["caramba"],
    categories: ["Home & Kitchen"], 
    link: "https://caramba.store/?utm_source=AllSorts&utm_medium=DM&utm_campaign=DM%20Test"
  },
  {
    background: "/brands/coco.png",
    backgroundHover: "/brands/hover-coco.png",
    keywords: ["coco"],
    categories: ["Home & Kitchen"], 
    link: "https://cococarpets.com/?utm_source=newsletter&utm_medium=email&utm_campaign=alex_flyers"
  },
  {
    background: "/brands/fable.png",
    backgroundHover: "/brands/hover-fable.png",
    keywords: ["fable"],
    categories: ["Home & Kitchen"], 
    link: "https://us.fablehome.co/?utm_source=catalog&utm_medium=direct_mail&utm_campaign=loveallsorts"
  },
  {
    background: "/brands/glasvin.png",
    backgroundHover: "/brands/hover-glasvin.png",
    keywords: ["glasvin"],
    categories: ["Home & Kitchen"], 
    link: "http://glas.vin/?utm_source=allsorts&utm_medium=allsorts&utm_campaign=J21"
  },
  // {
  //   background: "/brands/anova.png",
  //   backgroundHover: "/brands/hover-anova.png",
  //   keywords: ["anova"],
  //   categories: ["Home & Kitchen"], 
  //   link: "http://anovaculinary.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  {
    background: "/brands/mmann.png",
    backgroundHover: "/brands/hover-mmann.png",
    keywords: ["mmann"],
    categories: ["Home & Kitchen"], 
    link: "https://www.mmann.co/shop?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  },
  {
    background: "/brands/glovesdotcom.png",
    backgroundHover: "/brands/hover-glovesdotcom.png",
    keywords: ["gloves.com"],
    categories: ["Clothing & Accessories"], 
    link: "https://www.gloves.com/?utm_source=allsorts&utm_medium=catalog&utm_term=&utm_content=&utm_campaign=15off"
  },
  // {
  //   background: "/brands/ana_luisa.png",
  //   backgroundHover: "/brands/hover-ana_luisa.png",
  //   keywords: ["ana luisa"],
  //   categories: ["Clothing & Accessories"], 
  //   link: "http://analuisa.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  // {
  //   background: "/brands/warby_parker.png",
  //   backgroundHover: "/brands/hover-warby_parker.png",
  //   keywords: ["warby parker"],
  //   categories: ["Clothing & Accessories"], 
  //   link: "http://warbyparker.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  // {
  //   background: "/brands/allbirds.png",
  //   backgroundHover: "/brands/hover-allbirds.png",
  //   keywords: ["allbirds"],
  //   categories: ["Clothing & Accessories"], 
  //   link: "http://allbirds.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  {
    background: "/brands/mary_young.png",
    backgroundHover: "/brands/hover-mary_young.png",
    keywords: ["mary young"],
    categories: ["Clothing & Accessories"], 
    link: "http://www.maryyoung.com/?utm_source=newsletter&utm_medium=print&utm_campaign=allsorts"
  },
  {
    background: "/brands/alder.png",
    backgroundHover: "/brands/hover-alder.png",
    keywords: ["alder"],
    categories: [""], 
    link: "https://www.alderapparel.com/?utm_source=print&utm_medium=qr&utm_campaign=as"
  },
  {
    background: "/brands/hydrant.png",
    backgroundHover: "/brands/hover-hydrant.png",
    keywords: ["hydrant"],
    categories: ["Nutrition & Wellness"], 
    link: "https://www.drinkhydrant.com/?utm_source=all-sorts&utm_medium=catalog&utm_campaign=pilot"
  },
  {
    background: "/brands/earth_star.png",
    backgroundHover: "/brands/hover-earth_star.png",
    keywords: ["earth star"],
    categories: ["Nutrition & Wellness"], 
    link: "https://earthandstar.com/?utm_source=loveallsorts&utm_medium=qr&utm_campaign=directmail"
  },
  {
    background: "/brands/mojo.png",
    backgroundHover: "/brands/hover-mojo.png",
    keywords: ["mojo"],
    categories: ["Nutrition & Wellness"], 
    link: "https://mojogummy.gg/products/mojo-productivity-gummies?utm_source=AllSorts_Newsletter&utm_medium=Print&utm_campaign=AllSorts10"
  },
  {
    background: "/brands/paume.png",
    backgroundHover: "/brands/hover-paume.png",
    keywords: ["paume"],
    categories: ["Nutrition & Wellness"], 
    link: "https://mypaume.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  },
  // {
  //   background: "/brands/away.png",
  //   backgroundHover: "/brands/hover-away.png",
  //   keywords: ["away"],
  //   categories: ["Travel & Leisure"], 
  //   link: "http://awaytravel.com/?utm_source=allsorts&utm_medium=directmail&utm_campaign=edition1"
  // },
  {
    background: "/brands/clixo.png",
    backgroundHover: "/brands/hover-clixo.png",
    keywords: ["clixo"],
    categories: ["Family & Pets"], 
    link: "https://clixo.com/product/rainbow-pack/?utm_source=AllSorts&utm_medium=AllSorts&utm_campaign=Catalog&utm_content=Catalog"
  },
];
