<template>
  <div ref="survey" id="survey">
    <div>
      <a class="btn btn-primary" href="https://form.typeform.com/to/kF2CGXm0?typeform-medium=embed-snippet" target="_blank" ref="button" v-on:click.prevent>Take Survey</a>
    </div>
  </div>
</template>

<script>
import { createPopup } from '@typeform/embed'

export default {
  name: 'Survey',
  data: function () {
    return {}
  },
  props: {
  },
  mounted: function () {
    const { toggle } = createPopup('kF2CGXm0');
    this.$refs.button.onclick = toggle;

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../../node_modules/@typeform/embed/build/css/popup.css';
</style>