<template>
  <header :class="[{ 'show': show }]">
    <div class="container">
      <div class="row">
        <div class="col left">
          <router-link to="/"><img src="@/assets/logo.svg" alt="All Sorts" class="logo" /></router-link>
        </div>
        <div class="col right">
          <nav>
            <ul>
              <li><a href="/catalog" class="btn btn-outline-light" target="_blank" rel="noopener noreferrer">View catalog</a></li>
              <li><router-link to="/partner" class="btn btn-link">Partner with us</router-link></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function() {
    return {
      show: false,
    }
  },
  mounted: function() {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        this.show = true;
      }
      else {
        this.show = false;
      }
    }, { passive: true });
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";

header {
  background: black;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 0;
  opacity: 0;
  transition: opacity 250ms;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.right {
      justify-content: flex-end;
    }
  }

  .logo {
    width: 150px;
    height: auto;
  }

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        &:last-child {
          @media all and (max-width: $smallMax) {
            display: none;
          }
        }
      }

      a.btn-link {
        color: white;
      }
    }
  }
}
</style>
