<template>
  <div class="brand"
  :style="{ 
      'background-image': `url(${background}), url(${backgroundHover})`,
      '--bg-hover':`url(${backgroundHover})`
    }"/>
</template>

<script>
export default {
    name:"Brand",
    props: {
        background: String,
        backgroundHover: String
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";
.brand{
    width:100%;
    height:100%;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    transition: background-image 0.25s;
    border-radius: 4px;
    @media all and (max-width: $smallMax) {
        background-size: cover, cover;
    }
    &:hover{
        cursor: pointer;
        background-image: var(--bg-hover)!important;
    }
}
</style>